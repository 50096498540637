<template>
	<div class="layout-menus">
		<ul class="menus">
			<div
				class="title-box"
				:class="isMac ? 'title-box-mac' : ''"
				:style="{
					borderBottom: activeIndex == i ? '2px solid #fff' : '2px solid transparent',
				}"
				v-for="(item, i) in menus"
				:key="i"
				@click="clickTitle(item, i)"
			>
				<li class="item" :style="{cursor: i == 0 || i == 3 ? 'pointer' : ''}">
					{{ item.title }}
				</li>
				<span v-if="item.children.length != 0" class="iconfont icon-shanglajiantou"></span>
				<ul class="pull-down" v-if="item.children.length != 0" :class="'pull-down' + i">
					<li class="pull-item" :style="{color: key.url == path ? '#1181FA' : ''}" v-for="(key, index) in item.children" :key="index" @click.stop="clickTitle(key, index, i)" v-show="!key.hide">
						{{ key.title }}
					</li>
				</ul>
			</div>
		</ul>
	</div>
</template>

<script>
export default {
	data() {
		return {
			path: "", // 当前路由路径
			activeIndex: 0, //当前菜单index
			menus: [
				{
					title: "首页",
					url: "/home",
					children: [],
				},
				{
					title: "项目信息",
					url: "/project",
					children: [
						{
							title: "拟建信息",
							url: "/project/proposed-project",
						},
						{
							title: "土地交易",
							url: "/project/land-transaction",
						},
						{
							title: "招标信息",
							url: "/project/bidding-information",
						},
						{
							title: "中标信息",
							url: "/project/bidding-project-info",
						},
					],
				},
				{
					title: "客户信息",
					url: "/client",
					children: [],
				},
				{
					title: "行业信息",
					url: "/industry",
					children: [
						{
							title: "经济及行业数据",
							url: "/industry/economic-data",
							hide: true,
						},
						{
							title: "政策规划",
							url: "/industry/policy-planning",
						},
						{
							title: "建筑行业动态",
							url: "/industry/industry-dynamics",
						},
					],
				},
				{
					title: "数据服务",
					url: "/successful",
					children: [
						{
							title: "中标项目信息",
							url: "/successful/project-info",
							hide: true,
						},
						{
							title: "中标统计数据",
							url: "/successful/statistical-data",
						},
						{
							title: "中标高级查询",
							url: "/successful/advanced-query",
							hide: true,
						},
					],
				},
				{
					title: "产品超市",
					url: "/research",
					children: [
						{
							title: "数据超市",
							url: "/research/data-market",
						},
						{
							title: "专题报告",
							url: "/research/subdivide-market",
						},
						{
							title: "市场简报",
							url: "/research/new-market",
						},
					],
				},
				{
					title: "咨询服务",
					url: "/consult",
					children: [
						{
							title: "报告定制",
							url: "/consult/report-custom",
						},
						{
							title: "管理咨询",
							url: "/consult/manage-consult",
						},
					],
				},
			], //菜单数据
			isMac:false,//是否mac
		};
	},
	watch: {
		$route() {
			let path = this.$route.path;
			this.path = path;
			let {menus} = this;
			let key = -1;
			menus.forEach((item, index) => {
				// 判断当前路由属于哪个菜单底下
				if (path.includes(item.url)) {
					key = index;
				}
			});
			this.activeIndex = key;
			if (path=="/industry/bidding-detail"||path=="/industry/proposed-detail") {
		  	this.activeIndex=1
		}
		},
	},
	created () {
	let isMac = /macintosh|mac os x/i.test(navigator.userAgent);
		this.isMac=isMac	
	},
	mounted() {
		let path = this.$route.path;
		this.path = path;
		let {menus} = this;
		let key = -1;
		menus.forEach((item, index) => {
			// 判断当前路由属于哪个菜单底下
			if (path.includes(item.url)) {
				key = index;
			}
		});
		this.activeIndex = key;
		console.log('this.activeIndex',this.activeIndex,path);
		if (path=="/industry/bidding-detail"||path=="/industry/proposed-detail") {
		  	this.activeIndex=1
		}
	},
	methods: {
		// 点击菜单
		clickTitle(item, i, index) {
			let user = this.$session.getUsers();
			// 判断用户是否有身份
			if (user) {
				if (!user.consumerType) {
					this.$message.error("请选择身份类型");
					return;
				}
			}
			if (index) {
				// 二级菜单
				console.log("item",item);
				this.activeIndex = index;
				if (this.$route.path != item.url) {
					this.path = item.url;
					this.$router.push(item.url);
				}
			} else {
				// 一级菜单
				//  只有首页和客户信息可以进行跳转
				if (i == 0 || i == 2) {
					console.log('item.url',item.url);
					this.activeIndex = i;
					if (this.$route.path != item.url) {
						this.path = item.url;
						this.$router.push(item.url);
					}
				}
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
