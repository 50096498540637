<template>
	<div class="subdivide-market">
		<div class="title-card">
			<div class="title-box">
				<div class="title">数据超市</div>
				<div class="result">
					<span>共找到</span>
					<span class="num">{{ total }}</span>
					<span>条结果</span>
				</div>
			</div>
			<div class="condition-box">
				<div class="condition-item">
					<div class="title">省份</div>
					<div class="item-box">
						<div class="all-btn" :class="isProAll ? 'all-btn-select' : ''" @click="proAll">全部</div>
						<div class="name-box" :class="provincesStatus ? 'name-open' : ''">
							<div class="name" :class="proSelect.indexOf(item.code) !== -1 ? 'name-select' : ''" v-for="item in provinceData" :key="item.code" @click="selectPro(item)">
								{{ item.name }}
							</div>
						</div>
					</div>
					<div class="open" @click="foldProvinces">
						<span>{{ provincesStatus ? "收起" : "展开" }}</span>
						<span class="iconfont icon-sanjiaoxing" :class="provincesStatus ? 'iconfont-open' : ''"></span>
					</div>
				</div>
				<div class="condition-item" v-show="cityData.length != 0">
					<div class="title">地级市</div>
					<div class="item-box">
						<div class="name-box" :class="cityStatus ? 'name-open' : ''">
							<div class="name" :class="citySelect.indexOf(item.code) !== -1 ? 'name-select' : ''" v-for="item in cityData" :key="item.code" @click="selectCity(item)">
								{{ item.name }}
							</div>
						</div>
					</div>
					<div class="open" @click="foldCity">
						<span>{{ cityStatus ? "收起" : "展开" }}</span>
						<span class="iconfont icon-sanjiaoxing" :class="cityStatus ? 'iconfont-open' : ''"></span>
					</div>
				</div>
				<div class="condition-item" v-show="countyData.length != 0">
					<div class="title">区县</div>
					<div class="item-box">
						<div class="name-box" :class="countyStatus ? 'name-open' : ''">
							<div class="name" :class="countySelect.indexOf(item.code) !== -1 ? 'name-select' : ''" v-for="item in countyData" :key="item.code" @click="selectCounty(item)">
								{{ item.name }}
							</div>
						</div>
					</div>
					<div class="open" @click="foldCounty">
						<span>{{ countyStatus ? "收起" : "展开" }}</span>
						<span class="iconfont icon-sanjiaoxing" :class="countyStatus ? 'iconfont-open' : ''"></span>
					</div>
				</div>
				<div class="condition-item">
					<div class="title">行业</div>
					<div class="item-box">
						<div class="all-btn" :class="isIndustryAll ? 'all-btn-select' : ''" @click="industryAll">全部</div>
						<div class="name-box" :class="industryStatus ? 'name-open' : ''">
							<div class="name" :class="industrySelect.indexOf(item.code) !== -1 ? 'name-select' : ''" v-for="item in industryData" :key="item.code" @click="selectIndustry(item)">
								{{ item.name }}
							</div>
						</div>
					</div>
					<div class="open" @click="foldIndustry">
						<span>{{ industryStatus ? "收起" : "展开" }}</span>
						<span class="iconfont icon-sanjiaoxing" :class="industryStatus ? 'iconfont-open' : ''"></span>
					</div>
				</div>
				<div class="condition-item">
					<div class="title" style="margin-top: 10px">时间</div>
					<div class="year-box">
						<div class="all-btn" :class="isYearAll ? 'all-btn-select' : ''" @click="yearAll">不限</div>
						<div class="custom-box">
							<el-cascader v-model="cascader" ref="labelRef" :options="options" @change="handleCascaderChange"></el-cascader>
						</div>
					</div>
				</div>
				<div class="condition-item" v-show="selectedProData.length != 0 || selectedCityData.length != 0 || selectedCountyData.length != 0  || selectedIndustryData.length != 0 || selectedYearData.length != 0">
					<div class="title">已选条件</div>
					<div class="selected-box">
						<div class="name" v-for="(item, i) in selectedProData" :key="item.code">
							<div>{{ item.name }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 1)"></div>
						</div>
						<div class="name" v-for="(item, i) in selectedCityData" :key="item.code">
							<div>{{ item.name }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 2)"></div>
						</div>
						<div class="name" v-for="(item, i) in selectedCountyData" :key="item.code">
							<div>{{ item.name }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 2)"></div>
						</div>
						<div class="name" v-for="(item, i) in selectedIndustryData" :key="item.code">
							<div>{{ item.name }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 3)"></div>
						</div>
						<div class="name" v-for="(item, i) in selectedYearData" :key="item.code">
							<div>{{ item.name }}</div>
							<div class="clear-icon" @click="delSelectData(item, i, 4)"></div>
						</div>
					</div>
					<!-- <div class="empty-btn" @click="emptySelect">清空筛选条件</div> -->
				</div>
				<div class="search-button">
					<el-button type="primary" @click="handleQuery">搜索</el-button>
					<el-button @click="emptySelect">清空</el-button>
				</div>
			</div>
		</div>
		<div class="result-box" v-show="total == 0" style="margin-top: 100px">
			<empty :name="'暂无数据'"></empty>
		</div>
		<div class="result-box">
			<el-row :gutter="20">
				<el-col :span="12" v-for="(item, i) in pageReport" :key="i">
					<div class="grid-content" @click="toDetail(item)">
						<img @dragstart.prevent :src="item.frontCoverImg" alt="" />
						<div class="right-box">
							<el-tooltip effect="light" :content="item.reportTitle" placement="bottom-start">
								<div class="title text-ellipsis-two">
									{{ item.reportTitle }}
								</div>
							</el-tooltip>
							<div class="content text-ellipsis-four">
								{{ item.reportDesc }}
							</div>
							<div class="price-box">
								<div v-if="item.chargeType == '60451002'">
									价格：
									<span class="unit">￥</span>
									<span class="price">{{ item.downloadPrice }}</span>
									/期
								</div>
								<div v-if="item.chargeType == '60451001'">
									价格：
									<span class="unit"></span>
									<span style="color: #23ab82">免费</span>
								</div>
								<!-- <div class="down-price" v-if="item.chargeType == '60451002'">
									下载价格：
									<span class="unit">￥</span>
									<span class="price">{{ item.downloadPrice }}</span>
									/期
								</div>
								<div class="down-price" v-if="item.chargeType == '60451001'">
									下载价格：
									<span class="unit"></span>
									<span style="color: #23ab82">免费</span>
								</div> -->
							</div>
							<div class="member">
								会员价格：
								<span style="color: #23ab82">
									<span class="unit">￥</span>
									{{ item.memberPrice }}
								</span>
							</div>
							<div class="flex mt-36">
							<collect :ab="false" v-model="item.isCollection" :itemId="item.reportId" type='分析报告' />
							<!-- <DownloadButton type="市场分析简报" :id="item.reportId" :detailData="item"/> -->
							</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="pagination-box">
			<pagination ref="pagination" :total="total" @change="paginChange" v-show="total != 0"></pagination>
		</div>
		<login-modal ref="loginModal"></login-modal>
		<ToPayOrMember ref="toPayOrMember" :showPayBtn="showPayBtn" :conPO="payData" type="数据超市"></ToPayOrMember>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import LoginModal from "@/components/loginModal";
import Empty from "@/components/Empty";
import ToPayOrMember from "@/components/ToPayOrMember";
import PayModal from "@/components/payModal";
import collect from "@/components/collect";
import DownloadButton from "@/components/downloadButton";
export default {
	components: {
		Pagination,
		LoginModal,
		Empty,
		ToPayOrMember,
		PayModal,
		collect,
		DownloadButton
	},
	data() {
		return {
			provinceData: [], // 省份数据
			cityData: [], // 市级数据
			countyData: [], // 县级数据
			provincesStatus: false, // 省份展开收起状态
			cityStatus: false, // 地级市展开收起状态
			countyStatus: false, // 县级展开收起状态
			isProAll: true, // 省份是否全选
			proSelect: [], // 选中的省份
			citySelect: [], // 选中的地级市
			countySelect: [], // 选中的县级
			industryStatus: false, // 行业展开收起状态
			isIndustryAll: true, // 行业是否全选
			industrySelect: [], // 选中的行业
			industryData: [], // 行业数据
			isYearAll: true, // 年份是否全选
			yearSelect: [], // 选中的年份
			yearData: [],
			selectedProData: [], // 已选的省级数据
			selectedCityData: [], // 已选地级市数据
			selectedCountyData: [], // 已选县级数据
			selectedIndustryData: [], // 已选地行业数据
			selectedYearData: [], // 已选地年份数据
			startYear: "",
			endYear: "",
			cascader: [],
			options: [],
			pageReport: [],
			total: 0,
			conPO: {
				platform: 1,
				year: null,
				dateType: null,
				childModuleCode: "10011003",
				pageSize: 10, //页数
				pageNum: 1, // 页码
			},
			showPayBtn: false,
			payData: null,
		};
	},
	mounted() {
		// 数据埋点
		this.$api.home.buriedPoint({
			terminal: "60441001",
			consumerId: this.$session.getUsers() ? this.$session.getUsers().consumerId : "",
			positionCode: "604410011002",
		});
		this.getReportPage();//获取研报列表
		this.getReportCategory();//筛选行业
		this.getReportSearch();//筛选省份
		this.getPublishDate();//筛选时间
	},
	methods: {
		// 研报列表-查询条件获取（省-年份）
		getReportSearch() {
			this.$api.research
				.getReportSearch({
					platform: 1,
					childModuleCode: "10011003",
				})
				.then(res => {
					let data = res.data;
					this.provinceData = data.reportProvince;
					// data.reportYear.forEach(item => {
					// 	item.name = item.reportYear;
					// 	item.code = item.reportYear;
					// });
					// this.yearData = data.reportYear.sort((x, y) => y["reportYear"] - x["reportYear"]);
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 研报列表-查询条件获取（行业）
		getReportCategory() {
			this.$api.research
				.getReportCategory()
				.then(res => {
					if (res.data) {
						let data = res.data;
						data.forEach(item => {
							item.name = item.categoryName;
							item.code = item.id;
						});
						this.industryData = data;
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 研报列表-查询条件获取（市）
		getReportSearchCity(item) {
			let {proSelect, cityData, selectedProData} = this;
			this.$api.research
				.getReportSearchCity({
					platform: 1,
					childModuleCode: "10011003",
					provinceCode: item.code,
				})
				.then(res => {
					let data = res.data;
					proSelect.push(item.code);
					this.proSelect = proSelect;
					this.cityData = [...cityData, ...data];
					this.selectedProData = [...selectedProData, ...[item]];
					this.isProAll = false;
					// this.getReportPage();
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 研报列表-查询条件获取（县）
		getReportSearchCounty(item) {
			let {citySelect, countyData, selectedCityData} = this;
			const provinceCode = item.code.slice(0, 2).padEnd(item.code.length, '0');
			this.$api.research
				.getReportSearchCounty({
					platform: 1,
					childModuleCode: "10011003",
					provinceCode: provinceCode,
					cityCode: item.code,
				})
				.then(res => {
					let data = res.data;
					citySelect.push(item.code);
					this.citySelect = citySelect;
					this.countyData = [...countyData, ...data];
					this.selectedCityData = [...selectedCityData, ...[item]];
					this.isProAll = false;
					// this.getReportPage();
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		getPublishDate() {
			this.$api.research.getPublishDate().then(res => {
				this.options = res.rows;
			});
		},
		// 级联选择
		handleCascaderChange() {
			let select = this.$refs.labelRef.getCheckedNodes()[0].pathLabels;
			let str = select.reduce((prev, curr) => prev + " " + curr);
			let selectArr = [{name: str, code: str}];
			this.selectedYearData = selectArr;
			if (this.cascader.length == 0) {
				this.isYearAll = true;
			} else {
				this.isYearAll = false;
			}
		},
		// 点击搜索
		handleQuery() {
			this.$refs.pagination.page = 1;
			this.conPO.pageNum=1
			this.getReportPage();
		},
		// 获取研报列表
		getReportPage() {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: true,
			});
			let {conPO, proSelect, citySelect, countySelect, industrySelect, cascader} = this;
			conPO.province = proSelect.toString();
			conPO.city = citySelect.toString();
			conPO.county = countySelect.toString();
			conPO.categoryId = industrySelect.toString();
			conPO.year = cascader.length > 1 ? cascader[1] : null;
			conPO.reportMonth = cascader.length > 2 ? cascader[2] : null;
			conPO.dateType = cascader.length > 0 ? cascader[0] : null;
			this.$api.research
				.getReportPage(conPO)
				.then(res => {
					loading.close()
					let rows = res.rows;
					this.pageReport = rows;
					this.total = res.total;
				})
				.catch(msg => {
					loading.close()
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 展开收起省份
		foldProvinces() {
			this.provincesStatus = !this.provincesStatus;
		},
		// 展开收起地级市
		foldCity() {
			this.cityStatus = !this.cityStatus;
		},
		// 展开收起县级
		foldCounty() {
			this.countyStatus = !this.countyStatus;
		},
		// 展开收起行业
		foldIndustry() {
			this.industryStatus = !this.industryStatus;
		},
		// 单选省份
		selectPro(item) {
			let {proSelect, cityData, selectedProData, citySelect, selectedCityData} = this;
			let valueIndex = proSelect.indexOf(item.code);
			if (valueIndex == -1) {
				// 判断市级数据中是否已经存在当前省的市级数据
				if (cityData.some(key => key.code.slice(0, 2) == item.code.slice(0, 2))) {
					// 处理市级数据（不需要当前省级的市级数据）
					let newCitySelect = [];
					citySelect.forEach(key => {
						if (item.code.slice(0, 2) != key.slice(0, 2)) {
							newCitySelect.push(key);
						}
					});
					this.citySelect = newCitySelect;
					// 处理已选条件的市级数据（不需要当前省级的市级数据）
					let newSelectedCityData = [];
					selectedCityData.forEach(key => {
						if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
							newSelectedCityData.push(key);
						}
					});
					this.selectedCityData = newSelectedCityData;
					// 处理省级数据选中状态
					proSelect.push(item.code);
					this.selectedProData = [...selectedProData, ...[item]];
					if (this.proSelect.length == 0 && this.citySelect.length == 0) {
						this.isProAll = true;
					} else {
						this.isProAll = false;
					}
					// this.getReportPage();
					return;
				}
				// 选择
				this.getReportSearchCity(item);
			} else {
				// 取消选择
				proSelect.splice(valueIndex, 1);
				// 删除对应的市级数据
				let cityResult = [];
				cityData.forEach(key => {
					if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
						cityResult.push(key);
					}
				});
				this.cityData = cityResult;
				// （删除已选的省级数据）比较 item和selectedProData取出不同的值
				let itemArr = [item];
				let selectedCodes = [];
				itemArr.forEach(({code}) => {
					selectedCodes.push(code);
				});
				let selectedResult = selectedProData.filter(({code}) => !selectedCodes.includes(code));
				this.selectedProData = selectedResult;
				// this.getReportPage();
			}
			if (this.proSelect.length == 0 && this.citySelect.length == 0) {
				this.isProAll = true;
			} else {
				this.isProAll = false;
			}
		},
		// 全选省份
		proAll() {
			if (this.proSelect.length == 0 && this.citySelect.length == 0) {
				return;
			}
			this.isProAll = !this.isProAll;
			if (this.isProAll) {
				this.selectedProData = [];
				this.proSelect = [];
				this.cityData = [];
				this.selectedCityData = [];
				this.citySelect = [];
			}
			// this.getReportPage();
		},
		// 单选地级市
		selectCity(item) {
			let {citySelect, countyData, selectedCityData, countySelect, selectedCountyData} = this;
			let valueIndex = citySelect.indexOf(item.code);
			if (valueIndex == -1) {
				// 判断市级数据中是否已经存在当前省的市级数据
				if (countyData.some(key => key.code.slice(0, 2) == item.code.slice(0, 2))) {
					// 处理省级数据（不需要当前市级的省级数据）
					let newCountySelect = [];
					countySelect.forEach(key => {
						if (item.code.slice(0, 2) != key.slice(0, 2)) {
							newCountySelect.push(key);
						}
					});
					this.countySelect = newCountySelect;
					// 处理已选条件的省级数据（不需要当前市级的省级数据）
					let newSelectedCountyData = [];
					selectedCountyData.forEach(key => {
						if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
							newSelectedCountyData.push(key);
						}
					});
					this.selectedProData = newSelectedProData;
					// 处理地市级数据选中状态
					citySelect.push(item.code);
					this.selectedCityData = [...selectedCityData, ...[item]];
					if (this.citySelect.length == 0) {
						this.isProAll = false;
					}
					// this.getReportPage();
					return;
				}
				// 选择
				this.getReportSearchCounty(item);

			} else {
				// 取消选择
				citySelect.splice(valueIndex, 1);
				// 比较 item和selectedCityData取出不同的值
				let itemArr = [item];
				let selectedCodes = [];
				itemArr.forEach(({code}) => {
					selectedCodes.push(code);
				});
				let selectedResult = selectedCityData.filter(({code}) => !selectedCodes.includes(code));

				if (selectedResult.length == 0) {
					// 无选中的市时
					this.isProAll = true;
				}
				this.selectedCityData = selectedResult;
				// this.getReportPage();
			}
		},
		// 单选县级
		selectCounty(item) {
			let {countySelect, selectedCountyData, citySelect, selectedCityData} = this;
			let valueIndex = countySelect.indexOf(item.code);
			if (valueIndex == -1) {
				// 选择
				countySelect.push(item.code);
				this.selectedCountyData = [...selectedCountyData, ...[item]];
				// 处理市级数据（不需要当前县级的市级数据）
				let newCitySelect = [];
				citySelect.forEach(key => {
					if (item.code.slice(0, 4) != key.slice(0, 4)) {
						newCitySelect.push(key);
					}
				});
				this.citySelect = newCitySelect;
				// 处理已选条件的市级数据（不需要当前县级的市级数据）
				let newSelectedCityData = [];
				selectedCityData.forEach(key => {
					if (item.code.slice(0, 4) != key.code.slice(0, 4)) {
						newSelectedCityData.push(key);
					}
				});
				this.selectedCityData = newSelectedCityData;
				// this.getReportPage();
			} else {
				// 取消选择
				countySelect.splice(valueIndex, 1);
				// 比较 item和selectedCountyData取出不同的值
				let itemArr = [item];
				let selectedCodes = [];
				itemArr.forEach(({code}) => {
					selectedCodes.push(code);
				});
				let selectedResult = selectedCountyData.filter(({code}) => !selectedCodes.includes(code));
				this.selectedCountyData = selectedResult;
				// this.getReportPage();
			}
		},
		// 全选年份
		yearAll() {
			if (this.cascader.length == 0) {
				return;
			}
			this.isYearAll = !this.isYearAll;
			if (this.isYearAll) {
				this.cascader = [];
				this.startYear = "";
				this.endYear = "";
				this.selectedYearData = [];
				this.yearSelect = [];
			}

			// this.getReportPage();
		},
		// 单选行业
		selectIndustry(item) {
			let {industrySelect, selectedIndustryData} = this;
			let valueIndex = industrySelect.indexOf(item.code);
			if (valueIndex == -1) {
				// 选择
				industrySelect.push(item.code);
				this.selectedIndustryData = [...selectedIndustryData, ...[item]];
				// this.getReportPage();
			} else {
				// 取消选择
				industrySelect.splice(valueIndex, 1);
				// 比较 item和selectedIndustryData取出不同的值
				let itemArr = [item];
				let selectedCodes = [];
				itemArr.forEach(({code}) => {
					selectedCodes.push(code);
				});
				let selectedResult = selectedIndustryData.filter(({code}) => !selectedCodes.includes(code));
				this.selectedIndustryData = selectedResult;
				// this.getReportPage();
			}
			if (this.industrySelect.length == 0) {
				this.isIndustryAll = true;
			} else {
				this.isIndustryAll = false;
			}
		},
		// 全选行业
		industryAll() {
			if (this.industrySelect.length == 0) {
				return;
			}
			this.isIndustryAll = !this.isIndustryAll;
			if (this.isIndustryAll) {
				this.selectedIndustryData = [];
				this.industrySelect = [];
			}
		},
		// 删除单条所选内容
		delSelectData(item, index, type) {
			if (type == 1) {
				// 删除省
				let {proSelect, cityData, citySelect} = this;
				this.selectedProData.splice(index, 1);
				// 删除对应的市级数据
				let cityResult = [];
				cityData.forEach(key => {
					if (item.code.slice(0, 2) != key.code.slice(0, 2)) {
						cityResult.push(key);
					}
				});
				this.cityData = cityResult;
				let newData = proSelect.filter(key => ![item.code].includes(key));
				this.proSelect = newData;
				if (proSelect.length == 1 && citySelect.length == 0) {
					this.cityData = [];
				}
				// this.getReportPage();
				if (this.proSelect.length == 0 && this.citySelect.length == 0) {
					this.isProAll = true;
				} else {
					this.isProAll = false;
				}
			} else if (type == 2) {
				// 删除市
				let {citySelect, selectedProData} = this;
				this.selectedCityData.splice(index, 1);
				let newData = citySelect.filter(key => ![item.code].includes(key));
				if (newData.length == 0) {
					this.selectedCityData = [];
					this.citySelect = [];
				}
				if (newData.length == 0 && this.proSelect.length == 0) {
					this.isProAll = true;
				}
				this.citySelect = newData;
				if (citySelect.length == 1 && selectedProData.length == 0) {
					this.cityData = [];
				}
				// this.getReportPage();
			} else if (type == 3) {
				let {industrySelect} = this;
				this.selectedIndustryData.splice(index, 1);
				let newData = industrySelect.filter(key => ![item.code].includes(key));
				if (newData.length == 0) {
					this.isIndustryAll = true;
					this.selectedIndustryData = [];
					this.industrySelect = [];
				}
				this.industrySelect = newData;
				// this.getReportPage();
			} else if (type == 4) {
				// 删除年份
				let {yearSelect} = this;
				this.selectedYearData.splice(index, 1);
				let newData = yearSelect.filter(key => ![item.code].includes(key));
				if (newData.length == 0) {
					this.isYearAll = true;
					this.cascader = [];
					this.selectedYearData = [];
					this.yearSelect = [];
				}
				this.yearSelect = newData;
				// this.getReportPage();
			}
		},
		// 清空筛选条件
		emptySelect() {
			this.cityData = [];
			this.countyData = [];
			this.isProAll = true;
			this.proSelect = [];
			this.citySelect = [];
			this.countySelect = [];
			this.isIndustryAll = true;
			this.industrySelect = [];
			this.isYearAll = true;
			this.yearSelect = [];
			this.selectedProData = [];
			this.selectedCityData = [];
			this.selectedCountyData = [];
			this.selectedIndustryData = [];
			this.selectedYearData = [];
			this.startYear = "";
			this.endYear = "";
			this.cascader = [];
			this.getReportPage();
		},
		// 点击列表进入详情
		toDetail(item) {
			let { reportId } = item;
			this.payData=item
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				// 判断阅读，下载及会员情况
				this.$api.research
					.getReadAndDownload({
						reportId,
					})
					.then(res => {
						let data = res.data;
						if (data.read == "10011001") {
							// 有阅读权限
							if (data.isRead) {
								let url = this.$router.resolve({
									path: `/research/market-detail?type=3&id=${reportId}`,
								});
								window.open(url.href, "_blank");
							} else {
								let params = {
									moduleCode: 1001,
									childModuleCode: 10011003,
									type: 3,
								};
								this.$api.industry.getMemberPermission(params).then(res => {
									if (res.data != 0 && res.data > 0) {
										this.open(res.data, reportId);
									} else if (res.data < 0) {
										let url = this.$router.resolve({
											path: `/research/market-detail?type=3&id=${reportId}`,
										});
										window.open(url.href, "_blank");
									} else {
										this.text = "您的次数已用完，升级VIP会员"; 
										this.$refs.toPayOrMember.openDialog();
									}
								});
							}
						} else {
							console.log(111);
							// 没有阅读权限
							this.$refs.toPayOrMember.orderData = {reportId:reportId};
							this.$refs.toPayOrMember.openDialog();
							this.showPayBtn = true;
							// let url = this.$router.resolve({
							// 	path: `/research/market-preview?type=3&id=${reportId}`,
							// });
							// window.open(url.href, "_blank");
						}
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		open(tiems, reportId) {
			this.$confirm(`您还剩余${tiems}次查阅机会，是否消耗1次？`, "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					console.log(111);
					setTimeout(() => {
						let url = this.$router.resolve({
							path: `/research/market-detail?type=3&id=${reportId}`,
						});
						window.open(url.href, "_blank");
					}, 200);
				})
				.catch(() => {
					console.log(222);
					// this.$message({
					// 	type: "info",
					// 	message: "已取消",
					// });
				});
		},
		// 分页方法
		paginChange(page, pageSize) {
			this.conPO.pageNum = page;
			this.conPO.pageSize = pageSize;
			this.getReportPage();
		},
	},
};
</script>
<style lang="less" scope>
@import "../subdivideMarket/index.less";
</style>
