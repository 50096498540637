export const routes = [
			{
				path: "/login",
				name: "login",
				component: () => import("../views/login"),
			},
			{
				path: "/mobileLogin",
				name: "mobileLogin",
				meta: {
					needLogin: false, // 需要登录的页面
				},
				component: () => import("../views/login/mobile"),
			},
			{
				path: "/autoLogin",
				name: "autoLogin",
				component: () => import("../views/autoLogin"),
			},
			{
				path: "/agreement",
				name: "agreement",
				component: () => import("../views/agreement"),
			},
			{
				path: "/preview",
				name: "preview",
				component: () => import("../views/preview"),
			},
			{
				path: "/industry-preview",
				name: "industryPreview",
				component: () => import("../views/industryPreview"),
			},
			{
				path: "/report-custom-detail",
				name: "report-custom-detail",
				meta: {
					needLogin: false, // 需要登录的页面
				},
				component: () => import("../views/reportCustomDetail"),
			},
			{
				// 支付成功中转页面，不需要登录
				path: "/transfer",
				name: "transfer",
				meta: {
					needLogin: false, // 需要登录的页面
				},
				component: () => import("../views/transfer"),
			},
			{
				// 支付成功中转页面，不需要登录
				path: "/webview",
				name: "webview",
				meta: {
					needLogin: false, // 需要登录的页面
				},
				component: () => import("../views/webview"),
			},
			{
				path: "*",
				component: () => import("../layout"),
				redirect: "home",
				children: [
					{
						path: "/home",
						name: "home",
						meta: {
							title: "首页",
						},
						component: () => import("../views/home"),
					},
					{
						path: "/search",
						name: "search",
						meta: {
							title: "搜索页",
						},
						component: () => import("../views/search"),
					},
					{
						path: "/member",
						name: "member",
						meta: {
							title: "会员页",
							needLogin: true, // 需要登录的页面
						},
						component: () => import("../views/member"),
					},
					{
						path: "/about",
						name: "about",
						meta: {
							title: "关于我们",
						},
						component: () => import("../views/about"),
					},
					{
						path: "/research/regional-market",
						name: "regionalMarket",
						meta: {
							title: "区域市场研究报告",
						},
						component: () => import("../views/research/regionalMarket"),
					},
					{
						path: "/research/data-market",
						name: "dataMarket",
						meta: {
							title: "数据超市",
						},
						component: () => import("../views/research/dataMarket"),
					},
					{
						path: "/research/subdivide-market",
						name: "subdivideMarket",
						meta: {
							title: "专题报告",
						},
						component: () => import("../views/research/subdivideMarket"),
					},
					{
						path: "/research/new-market",
						name: "newMarket",
						meta: {
							title: "市场简报",
						},
						component: () => import("../views/research/newMarket"),
					},
					{
						path: "/research/market-preview",
						name: "marketPreview",
						meta: {
							title: "研究报告预览",
							needLogin: true, // 需要登录的页面
						},
						component: () => import("../views/research/marketPreview"),
					},
					{
						path: "/research/market-detail",
						name: "marketDetail",
						meta: {
							title: "研究报告详情",
							needLogin: true, // 需要登录的页面
						},
						component: () => import("../views/research/marketDetail"),
					},
					{
						path: "/successful/project-info",
						name: "projectInfo",
						meta: {
							title: "项目信息查询",
							needLogin: true, // 需要登录的页面
						},
						component: () => import("../views/successful/projectInfo"),
					},
					{
						path: "/successful/statistical-data",
						name: "statisticalData",
						meta: {
							title: "统计数据查询",
							needLogin: true, // 需要登录的页面
						},
						component: () => import("../views/successful/statisticalData"),
					},
					{
						path: "/successful/advanced-query",
						name: "advancedQuery",
						meta: {
							title: "高级查询",
							needLogin: true, // 需要登录的页面
						},
						component: () => import("../views/successful/advancedQuery"),
					},
					{
						path: "/consult/report-custom",
						name: "reportCustom",
						meta: {
							title: "报告定制",
						},
						component: () => import("../views/consult/reportCustom"),
					},
					{
						path: "/consult/manage-consult",
						name: "manageConsult",
						meta: {
							title: "管理咨询",
						},
						component: () => import("../views/consult/manageConsult"),
					},
					{
						path: "/consult/report-custom-details",
						name: "reportCustomDetails",
						meta: {
							title: "报告定制详情",
						},
						component: () => import("../views/consult/reportCustomDetails"),
					},
					{
						path: "/industry/industry-dynamics",
						name: "industryDynamics",
						meta: {
							title: "行业动态",
						},
						component: () => import("../views/industry/industryDynamics"),
					},
					{
						path: "/industry/economic-data",
						name: "economicData",
						meta: {
							title: "经济数据",
						},
						component: () => import("../views/industry/economicData"),
					},
					{
						path: "/industry/policy-planning",
						name: "policyPlanning",
						meta: {
							title: "政策规划",
						},
						component: () => import("../views/industry/policyPlanning"),
					},
					{
						path: "/industry/data-report",
						name: "dataReport",
						meta: {
							title: "资料报告",
						},
						component: () => import("../views/industry/dataReport"),
					},
					{
						path: "/project/bidding-information",
						name: "biddingInformation",
						meta: {
							title: "招标信息",
						},
						component: () => import("../views/industry/biddingInformation"),
					},
					{
						path: "/project/proposed-project",
						name: "proposedProject",
						meta: {
							title: "拟建项目",
						},
						component: () => import("../views/industry/proposedProject"),
					},
					{
						path: "/project/land-transaction",
						name: "landTransaction",
						meta: {
							title: "土地交易",
						},
						component: () => import("../views/industry/landTransaction"),
					},
					{
						path: "/project/land-detail",
						name: "landDetail",
						meta: {
							title: "土地交易详情",
							needLogin: true, // 需要登录的页面
						},
						component: () => import("../views/industry/landDetail"),
					},
					{
						path: "/industry/bidding-detail",
						name: "biddingDetail",
						meta: {
							title: "招标信息详情",
							needLogin: true, // 需要登录的页面
						},
						component: () => import("../views/industry/biddingDetail"),
					},
					{
						path: "/industry/proposed-detail",
						name: "proposedDetail",
						meta: {
							title: "拟建项目信息详情",
							needLogin: true, // 需要登录的页面
						},
						component: () => import("../views/industry/proposedDetail"),
					},
					{
						path: "/client",
						name: "clientInformation",
						meta: {
							title: "客户信息",
						},
						component: () => import("../views/industry/clientInformation"),
					},
					{
						path: "/client/client-detail",
						name: "clientDetail",
						meta: {
							title: "客户信息详情",
							needLogin: true, // 需要登录的页面
						},
						component: () => import("../views/industry/clientDetail"),
					},
					{
						path: "/client/consumer-detail",
						name: "consumerDetail",
						meta: {
							title: "我的客户详情",
							needLogin: true, // 需要登录的页面
						},
						component: () => import("../views/industry/consumerDetail"),
					},
					{
						path: "/industry/trackRecord-detail",
						name: "recordDetail",
						meta: {
							title: "项目跟踪记录详情",
							needLogin: true, // 需要登录的页面
						},
						component: () => import("../views/industry/consumerDetail/trackRecord/detail"),
					},
					{
						path: "/industry/dynamic-detail",
						name: "dynamicDetail",
						meta: {
							title: "客户动态详情",
							needLogin: true, // 需要登录的页面
						},
						component: () => import("../views/industry/dynamicDetail"),
					},
					{
						path: "/industry/industry-detail",
						name: "industryDetail",
						meta: {
							title: "报告详情",
							needLogin: true, // 需要登录的页面
						},
						component: () => import("../views/industry/industryDetail"),
					},
					{
						path: "/project/bidding-project-info",
						name: "biddingProjectInfo",
						meta: {
							title: "中标信息",
						},
						component: () => import("../views/industry/biddingProjectInfo"),
					},
					{
						path: "/project/bidding-project-detail",
						name: "biddingProjectDetail",
						meta: {
							title: "中标信息详情",
							needLogin: true, // 需要登录的页面
						},
						component: () => import("../views/industry/biddingProjectDetail"),
					},
					{
						path: "/user",
						name: "user",
						meta: {
							title: "个人中心",
							needLogin: true, // 需要登录的页面
						},
						component: () => import("../views/user"),
						redirect: "/user/user-home",
						children: [
							{
								path: "/user/user-home",
								name: "userHome",
								meta: {
									title: "首页",
									needLogin: true, // 需要登录的页面
								},
								component: () => import("../views/user/userHome"),
							},
							{
								path: "/user/my-collect",
								name: "userCollect",
								meta: {
									title: "我的收藏",
									needLogin: true, // 需要登录的页面
								},
								component: () => import("../views/user/collect/collection"),
							},
							{
								path: "/user/my-collect/subscription",
								name: "mySubscription",
								meta: {
									title: "我的订阅",
									needLogin: true, // 需要登录的页面
								},
								component: () => import("../views/user/collect/subscribe"),
							},
							{
								path: "/user/my-collect/customer",
								name: "myCustomer",
								meta: {
									title: "我的客户",
									needLogin: true, // 需要登录的页面
								},
								component: () => import("../views/user/collect/customer"),
								children: [],
							},
							{
								path: "/user/my-collect/customer/detail",
								name: "myCustomerDetail",
								meta: {
									title: "我的客户详情",
									needLogin: true, // 需要登录的页面
								},
								component: () => import("../views/user/collect/customer/detail"),
							},
							{
								path: "/user/my-collect/customer/trackRecord-detail",
								name: "trackRecordDetail",
								meta: {
									title: "项目跟踪记录详情",
									needLogin: true, // 需要登录的页面
								},
								component: () => import("../views/user/collect/customer/detail/trackRecord/detail"),
							},
							{
								path: "/user/my-order",
								name: "userOrder",
								meta: {
									title: "我的订单",
									needLogin: true, // 需要登录的页面
								},
								component: () => import("../views/user/order"),
								redirect: "/user/my-order/data-market-order",
								children: [
									{
										path: "/user/my-order/data-market-order",
										name: "dataMarketOrder",
										meta: {
											title: "数据超市订单",
											needLogin: true, // 需要登录的页面
										},
										component: () => import("../views/user/order/dataMarketOrder"),
									},
									{
										path: "/user/my-order/report-order",
										name: "reportOrder",
										meta: {
											title: "研究报告订单",
											needLogin: true, // 需要登录的页面
										},
										component: () => import("../views/user/order/reportOrder"),
									},
									{
										path: "/user/my-order/industry-order",
										name: "industryOrder",
										meta: {
											title: "行业动态订单",
											needLogin: true, // 需要登录的页面
										},
										component: () => import("../views/user/order/industryOrder"),
									},
									{
										path: "/user/my-order/custom-order",
										name: "customOrder",
										meta: {
											title: "咨询/定制服务订单",
											needLogin: true, // 需要登录的页面
										},
										component: () => import("../views/user/order/customOrder"),
									},
									{
										path: "/user/my-order/vip-order",
										name: "vipOrder",
										meta: {
											title: "会员服务订单",
											needLogin: true, // 需要登录的页面
										},
										component: () => import("../views/user/order/vipOrder"),
									},
									{
										path: "/user/my-order/data-order",
										name: "vipOrder",
										meta: {
											title: "数据收费订单",
											needLogin: true, // 需要登录的页面
										},
										component: () => import("../views/user/order/dataOrder"),
									},
									{
										path: "/user/order-record",
										name: "orderRecord",
										meta: {
											title: "会员购买记录",
											needLogin: true, // 需要登录的页面
										},
										component: () => import("../views/user/order/orderRecord"),
									},
								],
							},
							{
								path: "/user/my-invoice",
								name: "userInvoice",
								meta: {
									title: "我的发票",
									needLogin: true, // 需要登录的页面
								},
								component: () => import("../views/user/invoice"),
								redirect: "/user/my-invoice/be-invoice",
								children: [
									{
										path: "/user/my-invoice/be-invoice",
										name: "beInvoice",
										meta: {
											title: "待开发票",
											needLogin: true, // 需要登录的页面
										},
										component: () => import("../views/user/invoice/beInvoice"),
									},
									{
										path: "/user/my-invoice/invoiced",
										name: "invoiced",
										meta: {
											title: "已开发票",
											needLogin: true, // 需要登录的页面
										},
										component: () => import("../views/user/invoice/invoiced"),
									},
								],
							},
							{
								path: "/user/my-buyed",
								name: "userBuyed",
								meta: {
									title: "我的已购",
									needLogin: true, // 需要登录的页面
								},
								component: () => import("../views/user/buyed"),
								redirect: "/user/my-buyed/buyed-service",
								children: [
									{
										path: "/user/my-buyed/buyed-service",
										name: "buyedService",
										meta: {
											title: "已购服务",
											needLogin: true, // 需要登录的页面
										},
										component: () => import("../views/user/buyed/buyedService"),
									},
									{
										path: "/user/my-buyed/download-record",
										name: "downloadRecord",
										meta: {
											title: "下载记录",
											needLogin: true, // 需要登录的页面
										},
										component: () => import("../views/user/buyed/downloadRecord"),
									},
								],
							},
							{
								path: "/user/my-center",
								name: "userCenter",
								meta: {
									title: "个人中心",
									needLogin: true, // 需要登录的页面
								},
								component: () => import("../views/user/center"),
								redirect: "/user/my-center/account-manage",
								children: [
									{
										path: "/user/my-center/account-manage",
										name: "accountManage",
										meta: {
											title: "账号管理",
											needLogin: true, // 需要登录的页面
										},
										component: () => import("../views/user/center/accountManage"),
									},
									{
										path: "/user/my-center/modify-data",
										name: "modifyData",
										meta: {
											title: "修改资料",
											needLogin: true, // 需要登录的页面
										},
										component: () => import("../views/user/center/modifyData"),
									},
									{
										path: "/user/my-center/modify-phone",
										name: "modifyPhone",
										meta: {
											title: "修改绑定手机号",
											needLogin: true, // 需要登录的页面
										},
										component: () => import("../views/user/center/modifyPhone"),
									},
								],
							},
						],
					},
					{
						path: "/subscribe/list",
						name: "subscribeList",
						meta: {
							title: "自选订阅",
							needLogin: true, // 需要登录的页面
						},
						component: () => import("../views/subscribe/list"),
					},
					{
						path: "/viparea",
						name: "viparea",
						meta: {
							needLogin: true, // 需要登录的页面
						},
						component: () => import("../views/viparea"),
					},
				],
			},
		];
