<template>
	<div class="statistical-data">
		<div class="title-card">
			<div class="title-box">
				<div class="title">中标统计数据</div>
			</div>
			<div class="search-box">
				<div class="search-form">
					<div class="form-item">
						<div class="nav-list">
							<div class="nav-item" :class="navIndex == 1 ? 'nav-item-select' : ''" @click="clickNav(1)">按地区
							</div>
							<div class="nav-item" :class="navIndex == 2 ? 'nav-item-select' : ''" @click="clickNav(2)">按招标人
							</div>
							<div class="nav-item" :class="navIndex == 3 ? 'nav-item-select' : ''" @click="clickNav(3)">按中标人
							</div>
							<div class="dataDescription" @click="openDataDescription">数据说明</div>
						</div>
						<div class="form-top">
							<div v-show="navIndex == 1" class="form-lable" style="width: 50px">
								<span style="color: red">*</span>
								地区
							</div>
							<div v-show="navIndex == 1">
								<!-- 地区多选 -->
								<!-- <select-area v-show="memberId == 5 || memberId == 6" ref="selectArea" :key="selectAreaKey"></select-area> -->
								<!-- 地区单选 -->
								<el-cascader :options="areaList" :props="{
									value: 'code',
									label: 'name',
									children: 'childrenList',
									checkStrictly: true,
									multiple: true,
								}" multiline="true" clearable :value="selectArealist" collapse-tags :show-all-levels="false" ref="area"
									@change="areaChange"></el-cascader>
							</div>
							<div v-show="navIndex == 1" class="form-lable lable">项目类型</div>
							<div v-show="navIndex == 1">
								<select-type ref="selectType"></select-type>
							</div>
							<div v-show="navIndex == 2" class="form-lable" style="width: 65px">
								<span style="color: red">*</span>
								招标人
							</div>
							<div v-show="navIndex == 2">
								<el-autocomplete ref="autocomplete1" v-model="conPO.tenderee"
									:fetch-suggestions="getTenderee" :trigger-on-focus="false" placeholder="请输入招标人"
									clearable v-scrollLoad="handleScroll1">
									<template slot-scope="{item}">
										<el-tooltip effect="light" :content="item.value" placement="right-end">
											<div>
												{{ item.value }}
											</div>
										</el-tooltip>
									</template>
								</el-autocomplete>
							</div>
							<div v-show="navIndex == 3" class="form-lable" style="width: 65px">
								<span style="color: red">*</span>
								中标人
							</div>
							<div v-show="navIndex == 3">
								<el-autocomplete ref="autocomplete2" v-model="conPO.bidWinner"
									:fetch-suggestions="getBidWinner" :trigger-on-focus="false" placeholder="请输入中标人"
									clearable v-scrollLoad="handleScroll2">
									<template slot-scope="{item}">
										<el-tooltip effect="light" :content="item.value" placement="right-end">
											<div>
												{{ item.value }}
											</div>
										</el-tooltip>
									</template>
								</el-autocomplete>
							</div>
							<div class="form-lable lable">项目金额</div>
							<el-form ref="conPO" :model="conPO" :rules="rules" style="display: flex; align-items: center">
								<div>
									<el-form-item prop="winnerPriceStart" style="margin-bottom: 0">
										<el-input v-model="conPO.winnerPriceStart"
											oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="最低金额（万）"
											@blur="blurPrice('start')" clearable></el-input>
									</el-form-item>
								</div>
								<div class="line" style="margin-top: 1px"></div>
								<div>
									<el-form-item prop="winnerPriceEnd" style="margin-bottom: 0">
										<el-input v-model="conPO.winnerPriceEnd"
											oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="最高金额（万）"
											@blur="blurPrice('end')" clearable></el-input>
									</el-form-item>
								</div>
							</el-form>
							<div class="note">
								<span class="iconfont icon-zhushibiaozhui"></span>
								<div class="note-title animated fadeIn">
									<span>某金额以上可只填最低金额；某金额以下可只填最高金额</span>
								</div>
							</div>
						</div>
						<div class="form-bottom">
							<div class="form-lable" style="width: 50px">
								<span style="color: red">*</span>
								时间
							</div>
							<div class="date-picker">
								<el-date-picker v-model="conPO.dateStart" type="month" :picker-options="startTime"
									placeholder="选择开始日期" value-format="yyyy-MM" :default-value="maxDate"></el-date-picker>
							</div>
							<div class="line"></div>
							<div class="date-picker">
								<el-date-picker v-model="conPO.dateEnd" type="month" :picker-options="endTime"
									placeholder="选择结束日期" value-format="yyyy-MM" :default-value="maxDate"></el-date-picker>
							</div>
							<el-checkbox class="bidConsortium" v-show="navIndex == 2 || navIndex == 3"
								v-model="isBidConsortium">{{ navIndex == 2 ? '含联合体成员身份招标额' : '含联合体成员身份中标额' }}</el-checkbox>
							<div class="search-button">
								<el-button type="primary" @click="handleQuery">搜索</el-button>
								<el-button @click="resetQuery">清空</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-show="navIndex == 1">
			<by-area ref="byArea" :conPO="conPO" @handleTest="handleTest"></by-area>
		</div>
		<div v-show="navIndex == 2">
			<by-tenderee ref="byTenderee" :conPO="conPO" :isBidConsortium="isBidConsortium"></by-tenderee>
		</div>
		<div v-show="navIndex == 3">
			<by-bidwinner ref="byBidwinner" :conPO="conPO" :isBidConsortium="isBidConsortium"></by-bidwinner>
		</div>
		<login-modal ref="loginModal"></login-modal>
		<member-modal ref="memberModal"></member-modal>
		<WebSocket ref="webSocket"></WebSocket>
		<el-dialog :visible.sync="visible" width="50%" class="dataDescriptionDialog">
			<img class="dataDescriptionImg" src="../../../assets/img/successful/dataDescription.png" alt="" />
		</el-dialog>
	</div>
</template>

<script>
import LoginModal from "@/components/loginModal";
import MemberModal from "@/components/MemberModal";
// 按地区
import ByArea from "./byArea";
// 按招标人
import ByTenderee from "./byTenderee";
// 按中标人
import ByBidwinner from "./byBidwinner";
import SelectType from "@/components/SelectType";
import WebSocket from "../components/webSocket.vue";
export default {
	components: {
		LoginModal,
		MemberModal,
		ByArea,
		ByTenderee,
		ByBidwinner,
		SelectType,
		WebSocket,
	},
	data() {
		let price = (rule, value, callback) => {
			if (!value) {
				callback();
			} else {
				const reg = /^[0-9]{1,18}([.][0-9]{1,6})?$/;
				if (reg.test(value)) {
					callback();
				} else {
					return callback(new Error("最多18位整数，6位小数"));
				}
			}
		};
		return {
			memberId: "",
			minDate: "",
			maxDate: "",
			navIndex: 1,
			conPO: {
				projectName: "",
				tenderee: "",
				bidWinner: "",
				areaId: "",
				// projectType: "",
				winnerPriceStart: "",
				winnerPriceEnd: "",
				dateStart: "",
				dateEnd: "",
				pageSize: 10, //页数
				pageNum: 1, // 页码
			}, // 查询条件
			rules: {
				winnerPriceStart: [
					{
						validator: price,
						trigger: "blur",
					},
				],
				winnerPriceEnd: [
					{
						validator: price,
						trigger: "blur",
					},
				],
			},
			typeList: [], // 分类数据
			allQueryLimit: "", // 总查询次数
			queryLimit: "", // 剩余查询次数
			selectAreaKey: "",
			areaList: [],
			selectArealist: [],
			lastSelectedList: [],
			orderPO: {}, // 数据收费订单查询参数
			visible: false, //数据说明
			title: "数据说明",
			isBidConsortium: false,
			moreLoading1: false,
			page1: 1,
			queryString1: '',
			moreLoading2: false,
			page2: 1,
			queryString2: '',
		};
	},
	created() {
		this.$api.successful
			.getPublishedDateRange()
			.then(res => {
				let dayNum = this.getDaysInMonth(res.data.max);
				this.minDate = new Date(res.data.min + "-01 00:00:00");
				this.maxDate = new Date(res.data.max + `-${dayNum} 23:59:59`);
			})
			.catch(msg => {
				if (msg?.msg) {
					this.$message.error(msg?.msg);
				}
			});
		// this.$refs.payQRCode.visible=true
	},
	computed: {
		startTime() {
			return {
				disabledDate: time => {
					if (this.conPO.dateEnd) {
						return time.getTime() > new Date(this.conPO.dateEnd).getTime() || time > this.maxDate || time < this.minDate;
					} else {
						return time > this.maxDate || time < this.minDate;
					}
				},
			};
		},
		endTime() {
			return {
				disabledDate: time => {
					if (this.conPO.dateStart) {
						return time.getTime() < new Date(this.conPO.dateStart).getTime() || time > this.maxDate || time < this.minDate;
					} else {
						return time > this.maxDate || time < this.minDate;
					}
				},
			};
		},
	},
	mounted() {
		// 数据埋点
		this.$api.home.buriedPoint({
			terminal: "60441001",
			consumerId: this.$session.getUsers() ? this.$session.getUsers().consumerId : "",
			positionCode: "604410011006",
		});
		this.getAreaList();
		this.getBidProjectType();
		let params = JSON.parse(JSON.stringify(this.conPO));
		// delete params.projectType;
		this.memberId = this.$session.getUsers().memberId;
		let id = this.$route.query.id;
		if (id) {
			// 获取数据收费订单查询参数
			this.$api.personalCenter
				.getDataPreview({
					orderId: id,
				})
				.then(res => {
					let dataDesc = JSON.parse(res.data.dataDesc);
					dataDesc.params.dateStart = dataDesc.params.dateStart.slice(0, 7);
					dataDesc.params.dateEnd = dataDesc.params.dateEnd.slice(0, 7);
					this.orderPO = dataDesc.params;
					console.log("this.orderPO", this.orderPO);
					if (dataDesc.dataType == 0) {
						// 按地区
						this.$refs.byArea.getTableData(dataDesc.params);
					} else if (dataDesc.dataType == 1) {
						// 按招标人
						this.navIndex = 2;
						this.$refs.byTenderee.showType = 1;
						this.$refs.byTenderee.getParams(dataDesc.params);
					} else {
						// 按中标人
						this.navIndex = 3;
						this.$refs.byBidwinner.showType = 1;
						this.$refs.byBidwinner.getParams(dataDesc.params);
					}
				});
		} else {
			// 获取默认按地区数据
			this.$nextTick(() => {
				this.$refs.byArea.getTableData(params);
			})

		}


	},
	methods: {
		// 数据说明
		openDataDescription() {
			this.visible = true;
		},
		getDaysInMonth(yuanmonth) {
			let date = new Date(yuanmonth);
			let month = date.getMonth();
			let days = new Date(date.getFullYear(), month + 1, 0).getDate();
			return days;
		},
		// 触发验证弹窗
		handleTest() {
			this.$refs.webSocket.isShow = true;
		},
		// 招标人联想搜索
		getTenderee(queryString, callback) {
			this.queryString2 = queryString
			this.$api.successful
				.pageTenderee({
					tenderee: queryString,
					pageNum: 1,
					pageSize: 10
				})
				.then(res => {
					let newData = [];
					res.rows.forEach(item => {
						let obj = {
							value: item,
						};
						newData.push(obj);
					});
					callback(newData);
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		handleScroll1() {
			if (this.moreLoading1) {
				return
			}
			this.moreLoading1 = true
			this.page1 += 1
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.$api.successful
				.pageTenderee({
					tenderee: this.queryString1,
					pageSize: 10,
					pageNum: this.page1
				})
				.then(res => {
					loading.close();
					let newData = [];
					res.rows.forEach(item => {
						let obj = {
							value: item,
						};
						newData.push(obj);
					});
					this.$refs.autocomplete1.$data.suggestions.push(...newData)
					this.$nextTick(() => {
						this.moreLoading1 = !res.rows.length
					})
				})
				.catch(msg => {
					loading.close();
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});

		},
		handleScroll2() {
			if (this.moreLoading2) {
				return
			}
			this.moreLoading2 = true
			this.page2 += 1
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.$api.successful
				.pageBidWinner({
					bidWinner: this.queryString2,
					pageSize: 10,
					pageNum: this.page2
				})
				.then(res => {
					loading.close();
					let newData = [];
					res.rows.forEach(item => {
						let obj = {
							value: item,
						};
						newData.push(obj);
					});
					this.$refs.autocomplete2.$data.suggestions.push(...newData)
					this.$nextTick(() => {
						this.moreLoading2 = !res.rows.length
					})
				})
				.catch(msg => {
					loading.close();
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});

		},
		// 中标人联想搜索
		getBidWinner(queryString, callback) {
			this.queryString2 = queryString
			this.$api.successful
				.pageBidWinner({
					bidWinner: queryString,
					pageNum: 1,
					pageSize: 10
				})
				.then(res => {
					let newData = [];
					res.rows.forEach(item => {
						let obj = {
							value: item,
						};
						newData.push(obj);
					});
					callback(newData);
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 输入最低/最高金额后
		blurPrice(type) {
			let { winnerPriceStart, winnerPriceEnd } = this.conPO;
			if (type == "start") {
				if (+winnerPriceStart > +winnerPriceEnd && winnerPriceEnd != "") {
					this.$message.error("最低金额不能大于最高金额");
					this.conPO.winnerPriceStart = "";
				}
			} else {
				if (+winnerPriceEnd < +winnerPriceStart && winnerPriceStart != "") {
					this.$message.error("最高金额不能小于最低金额");
					this.conPO.winnerPriceEnd = "";
				}
			}
		},
		// 省市区获取
		getAreaList() {
			this.$api.successful
				.getAreaList()
				.then(res => {
					for (let i = 0; i < res.data.length; i++) {
						res.data[i].type = "省";
						for (let j = 0; j < res.data[i].childrenList.length; j++) {
							res.data[i].childrenList[j].type = "市";
							for (let k = 0; k < res.data[i].childrenList[j].childrenList.length; k++) {
								res.data[i].childrenList[j].childrenList[k].type = "区";
							}
						}
					}
					res.data.map(item => {
						item.childrenList.map(item1 => {
							item1.childrenList.unshift({
								name: "全部区级",
								fullnames: "全部区级",
								code: "全部区级" + item1.code,
							});
						});
						item.childrenList.unshift({
							name: "全部市级",
							fullnames: "全部市级",
							code: "全部市级" + item.code,
						});
					});
					let nationwideItem = {
						name: "全国",
						fullnames: "全国",
						nationwide: "全国",
						code: "全国",
					};
					let allProvince = {
						name: "全部省级",
						fullnames: "全部省级",
						code: "全部省级",
					};
					res.data.unshift(nationwideItem, allProvince);
					// if (!(this.memberId == 5 || this.memberId == 6)) {
					// 	// 普通用户地区单选
					this.areaList = res.data;
					console.log("this.areaList", this.areaList);
					// } else {
					// 	// 地区多选
					// 	this.$refs.selectArea.areaList = res.data;
					// }
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		areaChange(e) {
			this.selectArealist = JSON.parse(JSON.stringify(e));
			console.log("e", e, this.lastSelectedList);
			if (e.length > this.lastSelectedList.length) {
				//添加了按钮
				e.map(item => {
					item.map(i => {
						if (i.indexOf("全部省级") != -1) {
							console.log(123);
							this.addAllProvince();
						}
						if (i.indexOf("全部市级") != -1) {
							this.addAllCity(i.slice(4, 16));
						}
						if (i.indexOf("全部区级") != -1) {
							this.addAllCounty(i.slice(4, 16));
						}
					});
				});
			} else {
				//取消了某个按钮,判断是否是取消了全选
				this.lastSelectedList.map(item => {
					item.map(i => {
						if (i.indexOf("全部省级") != -1) {
							this.deleteAllProvince();
						}
						if (i.indexOf("全部市级") != -1) {
							//判断是否是取消了全选
							// console.log('取消了全选',i)
							let thisALL = false;
							e.map(item1 => {
								if (item1.length == 2) {
									item1.map(i1 => {
										if (i1 == i) {
											thisALL = true;
										}
									});
								}
							});
							if (!thisALL) {
								//取消了全选
								console.log("取消了全选市级", i);
								this.deleteAllCity(i.slice(4, 16));
							}
						}
						if (i.indexOf("全部区级") != -1) {
							let thisALL = false;
							e.map(item1 => {
								if (item1.length == 3) {
									item1.map(i1 => {
										if (i1 == i) {
											thisALL = true;
										}
									});
								}
							});
							if (!thisALL) {
								//取消了全选
								console.log("取消了全选区级", i);
								this.deleteAllCounty(i.slice(4, 16));
							}
						}
					});
				});
			}
			this.lastSelectedList = JSON.parse(JSON.stringify(this.selectArealist));
		},
		addAllProvince(e) {
			let arr = [];
			this.areaList.map(item => {
				arr.push([item.code]);
			});
			this.$set(this, "selectArealist", [...new Set(this.selectArealist.concat(arr))]);
			this.selectArealist.splice(0, 2);
		},
		addAllCity(e) {
			console.log("e addAllCity", e);
			let arr = [];
			this.areaList.map(item => {
				if (item.code == e && item.childrenList) {
					item.childrenList.map(i => {
						if (i.name != "全部市级") {
							arr.push([item.code, i.code]);
						}
					});
				}
			});
			this.$set(this, "selectArealist", [...new Set(this.selectArealist.concat(arr))]);
		},
		addAllCounty(e) {
			let arr = [];
			this.areaList.map(item => {
				if (item.childrenList) {
					item.childrenList.map(i => {
						if (i.code == e && i.childrenList) {
							i.childrenList.map(j => {
								if (j.name != "全部区级") {
									arr.push([item.code, i.code, j.code]);
								}
							});
						}
					});
				}
			});
			this.$set(this, "selectArealist", [...new Set(this.selectArealist.concat(arr))]);
		},
		deleteAllProvince(e) {
			this.selectArealist = this.selectArealist.filter(item => !(item.length == 1 && item[0]));
		},
		deleteAllCity(e) {
			this.selectArealist = this.selectArealist.filter(item => !(item.length == 2 && item[1] && item[1].slice(0, 2) == e.slice(0, 2)));
		},
		deleteAllCounty(e) {
			this.selectArealist = this.selectArealist.filter(item => !(item.length == 3 && item[2] && item[2].slice(0, 4) == e.slice(0, 4)));
		},
		// 项目类型获取
		getBidProjectType() {
			this.$api.successful
				.getBidProjectType()
				.then(res => {
					// this.typeList = res.data;
					this.$refs.selectType.typeList = res.data;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 点击切换查看状态
		clickNav(index) {
			if (this.navIndex == index) {
				return;
			}
			this.resetQuery();
			this.navIndex = index;
			this.isBidConsortium = false
		},
		// 点击筛选
		handleQuery() {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				this.$refs.conPO.validate(valid => {
					if (valid) {
						let { navIndex, conPO, memberId } = this;
						// if (memberId == 5 || memberId == 6) {
						// 地区多选
						let selectList = this.selectArealist;
						conPO.nationwide = selectList
							.filter(item => item.length == 1)
							.map(item => item[0])
							.includes("全国")
							? "全国"
							: "";
						conPO.province = selectList.filter(item => item.length == 1).map(item => item[0]);
						conPO.city = selectList.filter(item => item.length == 2).map(item => item[1]);
						conPO.projectCounty = selectList.filter(item => item.length == 3).map(item => item[2]);

						let params = JSON.parse(JSON.stringify(conPO));
						params.moduleCode = 1002;
						params.childModuleCode = 10021002;
						if (navIndex == 1) {
							// params.projectTypeList = params.projectType;
							params.projectTypeList = this.$refs.selectType.typeSelect;
							if (params.projectTypeList == "") {
								params.projectTypeList = [];
							}
							params.dateStart = params.dateStart == null ? "" : params.dateStart;
							params.dateEnd = params.dateEnd == null ? "" : params.dateEnd;
							// if (memberId == 5 || memberId == 6) {
							// 地区多选
							if ((!conPO.province || !conPO.province.length) && (!conPO.city || !conPO.city.length) && (!conPO.projectCounty || !conPO.projectCounty.length)) {
								this.$message.error("请选择地区");
								return;
							}
							// } else {
							// 	// 地区单选

							// 	if (!conPO.areaId || conPO.areaId.length == 0) {
							// 		this.$message.error("请选择地区");
							// 		return;
							// 	}
							// }
							// delete params.projectType;
							if (!conPO.dateStart || !conPO.dateEnd) {
								this.$message.error("请选择时间");
								return;
							}
							// 按地区查询
							console.log(params);
							this.$refs.byArea.getTableData(params);
						} else if (navIndex == 2) {
							// 按招标人查询
							params.bidConsortium = '';
							if (this.isBidConsortium) {
								params.tendereeConsortium = params.tenderee;
							} else {
								params.tendereeConsortium = '';
							}
							if (!params.tenderee) {
								this.$message.error("请输入招标人");
								return;
							}
							if (!conPO.dateStart || !conPO.dateEnd) {
								this.$message.error("请选择时间");
								return;
							}
							this.$refs.byTenderee.showType = 1;
							this.$refs.byTenderee.getParams(params);
						} else {
							// 按中标人查询
							params.tendereeConsortium = '';
							if (this.isBidConsortium) {
								params.bidConsortium = params.bidWinner;
							} else {
								params.bidConsortium = '';
							}
							if (!params.bidWinner) {
								this.$message.error("请输入中标人");
								return;
							}
							if (!conPO.dateStart || !conPO.dateEnd) {
								this.$message.error("请选择时间");
								return;
							}
							this.$refs.byBidwinner.showType = 1;
							this.$refs.byBidwinner.getParams(params);
						}
					} else {
						return false;
					}
				});
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 点击清空
		resetQuery() {
			this.conPO = {
				projectName: "",
				tenderee: "",
				bidWinner: "",
				// projectType: "",
				winnerPriceStart: "",
				winnerPriceEnd: "",
				dateStart: "",
				dateEnd: "",
				pageSize: 10,
				pageNum: 1,
			};
			this.$refs.conPO.resetFields();
			// if (this.memberId == 5 || this.memberId == 6) {
			this.selectAreaKey = new Date().getTime();
			// }
			this.getAreaList();
			this.selectArealist = [];
			this.$refs.selectType.typeSelect = [];
		},
	},
};
</script>

<style lang="less">
@import "./index.less";
</style>
