<template>
  <div class="report-container">
    <div class="report-title-box">
      <div>下载记录</div>
      <div class="title-right">
        <span class="service">客服电话：</span>
        <span>{{ servicePhone }}</span>
      </div>
    </div>
    <div class="divider"></div>
    <el-tabs v-model="activeName" @tab-click="handleClick" class="tabs-box">
      <el-tab-pane name="60611003">
        <span slot="label">数据超市</span>
      </el-tab-pane>
      <el-tab-pane name="60611001">
        <span slot="label">市场简报</span>
      </el-tab-pane>
      <el-tab-pane name="60631001,60631002,60631003,60611003">
        <span slot="label">历史项目数据</span>
      </el-tab-pane>
      <el-tab-pane name="60611002">
        <span slot="label">行业动态</span>
      </el-tab-pane>
    </el-tabs>
    <div v-if="total == 0" style="margin-top: 150px">
      <empty name="暂无数据"></empty>
    </div>
    <div class="report-content" v-show="total != 0">
      <div class="report-box">
        <el-table :data="tableData" style="width: 100%" header-cell-class-name="table_header">
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column prop="" label="文件名称" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span>{{ scope.row.fileName }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="fileExtension" label="文件类型" width="150"></el-table-column> -->
          <el-table-column prop="downloadDate" label="下载日期" width="220"></el-table-column>
          <el-table-column prop="" label="状态" width="100">
            <template slot-scope="scope"><span :class="scope.row.downloadState == '60551001'
              ? 'download'
              : scope.row.downloadState == '60551002'
                ? 'undownload'
                : ''
              ">{{
    scope.row.downloadState == "60551001"
    ? "已下载"
    : scope.row.downloadState == "60551002"
      ? "未下载"
      : ""
  }}</span></template>
          </el-table-column>
          <el-table-column prop="" label="操作" width="80">
            <template slot-scope="scope">
              <span class="view-style" @click="dowloadFileRecord(scope.row)">下载</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <pagination ref="pagination" :total="total" @change="paginChange" v-show="total != 0">
        </pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import { mapGetters } from "vuex";
export default {
  components: {
    Pagination,
    Empty
  },
  data() {
    return {
      total: 0,
      tableData: [],
      params: {
        pageNum: 1,
        pageSize: 10,
        relationType: '60611001',
        childModuleCode: '10011003',//数据超市：10011003，市场简报：10011002
      },
      activeName: '60611003',
    };
  },
  computed: {
    ...mapGetters(['servicePhone'])
  },
  created() { },
  mounted() {
    this.downloadHistory();
  },
  methods: {
    handleClick() {
      this.params.relationType = this.activeName
      //数据超市与市场简报值相同
      if(this.activeName == '60611003'){
        this.params.relationType = '60611001'
      }
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.tableData = [];
      if(this.activeName == '60611001'){
        //市场简报
        this.params.childModuleCode = '10011002'
      }else if(this.activeName == '60611003'){
        //数据超市
        this.params.childModuleCode = '10011003'
      }else{
        this.params.childModuleCode = ''
      };
      this.downloadHistory();
    },
    // 获取列表
    downloadHistory() {
      this.params.relationTypeList = this.params.relationType.split(',')
      this.$api.personalCenter.downloadHistory(this.params).then(res => {
        this.tableData = res.rows;
        this.total = res.total;
      });
    },
    // 切换分页
    // 切换分页
    paginChange(page, pageSize) {
      this.params.pageNum = page;
      this.params.pageSize = pageSize;
      if(this.activeName == '60611001'){
        //市场简报
        this.params.childModuleCode = '10011002'
      }else if(this.activeName == '60611003'){
        //数据超市
        this.params.childModuleCode = '10011003'
      }else{
        this.params.childModuleCode = ''
      };
      this.downloadHistory();
    },
    //下载记录下载
    async dowloadFileRecord(item) {
      if (item.relationType != '60611001' && item.relationType != '60631001' && item.relationType != '60631002' && item.relationType != '60631003' && item.relationType != '60611003' && item.relationType != '60611002') {
        this.$message({
          message: '文件类型不存在!',
          type: 'warning'
        });
      }
      const loading = this.$loading({
        lock: true,
        text: "下载中...",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.3)",
        fullscreen: false,
      });
      // 进入下载  
      if (item.relationType == '60611001') {
        this.$api.research
          .getReadAndDownload({
            reportId: item.relationId,
          })
          .then(res => {
            if (res.code == 200) {
              let data = res.data;
              if (data.download == "10011001") {
                // 有下载权限
                this.$api.successful.getReportPdf({ reportId: item.relationId, type: 2 }).then(res => {
                  this.$utils.downloadFile(res, `${item.fileName}.pdf`);
                }).finally(() => {
                  loading.close();
                });
              } else {
                // 无下载权限，提示购买下载

              }
            } else {
              this.$message({
                message: '未找到文件',
                type: "warning",
              });
            }
          }).catch(() => {
            loading.close();
          });
      }

      if (item.relationType == '60631001' || item.relationType == '60631002' || item.relationType ==
        '60631003' || item.relationType == '60611003') {
        let paramsObj = item.downloadCondition
        this.$api.successful.getParams(paramsObj).then(async res => {
          if (res.code == 200) {
            let fileData = null
            if (item.relationType == '60631001') {
              fileData = await this.$api.successful.exportStatisticsByAreaByStr({ str: res.data })
            } else if (item.relationType == '60631002') {
              fileData = await this.$api.successful.exportStaTendereeByWinnerByStr({ str: res.data })
            } else if (item.relationType == '60631003') {
              fileData = await this.$api.successful.exportStaBidWinnerByTendereeByStr({ str: res.data })
            } else if (item.relationType == '60611003') {
              fileData = await this.$api.successful.getEconomicDatabaseDownloadByStr({ str: res.data })
            }
            this.$utils.downloadFile(fileData, `${item.fileName}.xlsx`);
            loading.close();
          } else {
            if (res.code == '10001111') {
              // this.showMore = true
              // this.$showPrompt({
              //   btnOkTit: '会员开通',
              //   btnCancelTit: '',
              //   success: res => {
              //     // 会员开通
              //     uni.navigateTo({
              //       url: `/pages_two/open-membership/index`,
              //     });
              //   },
              //   cancel: res => {
              //     // 按条购买
              //     uni.showToast({
              //       title: '正在开发中',
              //       icon: "none",
              //     });
              //   }
              // });
            } else {
              // uni.showToast({
              //   title: res.msg,
              //   icon: "none",
              // });
            }
          }
        })
      }
      if (item.relationType == '60611002') {
        this.$utils.fileLinkToStreamDownload(item.localFileUrl, item.fileName, item.fileExtension)
        setTimeout(() => {
          loading.close();
        }, 5000);
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
