<template>
	<div class="by-area">
		<div class="empty-box" v-show="tableData.length == 0">
			<empty :name="'暂无数据'"></empty>
		</div>
		<div class="result" v-show="tableData.length != 0">
			<div style="display: flex; align-items: center">
				<div class="result-num">搜索结果</div>
				<!-- <div v-show="isTime" style="margin-left: 20px">时间：2021年1月-2022年12月</div> -->
			</div>
			<div class="flex">
				<div v-if="downloadButtonShow" class="download-box mr-20" @click="toDownload">
					<span class="iconfont icon-download"></span>
					<span>下载</span>
				</div>
				<!-- <div v-if="isSensitive" class="download-box">
				<span>开通权限可查看全部信息</span>
				<el-button style="margin-left: 10px;" type="primary" size="mini" @click="toPay">立即开通</el-button>
			</div> -->
				<div class="download-box" v-show="!(memberId == 5 || memberId == 6)">
					<span class="tips">如需获取定制服务，请联系客服反馈需求</span>
				</div>
			</div>
		</div>
		<div class="result-box" v-show="tableData.length != 0">
			<div>
				<el-table ref="tableList" :data="tableData" row-key="id" :tree-props="{children: 'statisticsDtlVoList', hasChildren: 'hasChildren'}" style="width: 100%" header-cell-class-name="table_header" default-expand-all show-summary :summary-method="getSummaries">
					<el-table-column prop="area" label="地区" width="110" align="center">
						<template slot-scope="scope">
							<span style="padding-left: 10px">{{ scope.row.area }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="projectType" label="项目类型" align="center"></el-table-column>
					<el-table-column prop="projectCount" label="项目数量（个）" :sortable="isSensitive == false ? true : false" align="center" :render-header="renderHeader">
						<template slot-scope="scope">
							<div class="sort-price">
								<span>{{ scope.row.projectCount }}</span>
								<div class="sort-icon" v-show="scope.row.projectType == '汇总' && scope.row.statisticsDtlVoList.length != 0">
									<div class="icon_box">
										<i style="color: #c0c4cc" class="iconfont icon-jiangxu" v-show="!scope.row.sort || !typeSort[0]"></i>
										<i style="color: #1181fa" class="iconfont icon-jiangxu" v-show="scope.row.sort == 'descending' && typeSort[0]"></i>
										<i style="color: #1181fa" class="iconfont icon-shengxu" v-show="scope.row.sort == 'ascending' && typeSort[0]"></i>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="projectPrice" label="中标金额（亿元）" align="center" :sortable="isSensitive == false ? true : false" :render-header="renderHeader">
						<template slot-scope="scope">
							<div class="sort-price">
								<span>{{ scope.row.projectPrice }}</span>
								<div class="sort-icon" v-show="scope.row.projectType == '汇总' && scope.row.statisticsDtlVoList.length != 0">
									<div class="icon_box">
										<i style="color: #c0c4cc" class="iconfont icon-jiangxu" v-show="!scope.row.sort || !typeSort[1]"></i>
										<i style="color: #1181fa" class="iconfont icon-jiangxu" v-show="scope.row.sort == 'descending' && typeSort[1]"></i>
										<i style="color: #1181fa" class="iconfont icon-shengxu" v-show="scope.row.sort == 'ascending' && typeSort[1]"></i>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="tendereeCount" label="招标人数量（个）" align="center" :sortable="isSensitive == false ? true : false" :render-header="renderHeader">
						<template slot-scope="scope">
							<div class="sort-price">
								<span style="width: 50px">{{ scope.row.tendereeCount }}</span>
								<div class="sort-icon" v-show="scope.row.projectType == '汇总' && scope.row.statisticsDtlVoList.length != 0">
									<div class="icon_box">
										<i style="color: #c0c4cc" class="iconfont icon-jiangxu" v-show="!scope.row.sort || !typeSort[2]"></i>
										<i style="color: #1181fa" class="iconfont icon-jiangxu" v-show="scope.row.sort == 'descending' && typeSort[2]"></i>
										<i style="color: #1181fa" class="iconfont icon-shengxu" v-show="scope.row.sort == 'ascending' && typeSort[2]"></i>
									</div>
								</div>
								<div class="btn-detail" @click="toDetail(scope.row, 1)">详情</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="bidWinnerCount" label="中标人数量（个）" align="center" :sortable="isSensitive == false ? true : false" :render-header="renderHeader">
						<template slot-scope="scope">
							<div class="sort-price">
								<span style="width: 50px">{{ scope.row.bidWinnerCount }}</span>
								<div class="sort-icon" v-show="scope.row.projectType == '汇总' && scope.row.statisticsDtlVoList.length != 0">
									<div class="icon_box">
										<i style="color: #c0c4cc" class="iconfont icon-jiangxu" v-show="!scope.row.sort || !typeSort[3]"></i>
										<i style="color: #1181fa" class="iconfont icon-jiangxu" v-show="scope.row.sort == 'descending' && typeSort[3]"></i>
										<i style="color: #1181fa" class="iconfont icon-shengxu" v-show="scope.row.sort == 'ascending' && typeSort[3]"></i>
									</div>
								</div>
								<div class="btn-detail" @click="toDetail(scope.row, 2)">详情</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<!-- <div class="pagination-box">
        <pagination
          ref="pagination"
          :total="total"
          @change="paginChange"
          v-show="total != 0"
        ></pagination>
      </div> -->
		</div>
		<member-modal ref="memberModal"></member-modal>
		<detail-modal ref="detaiModal"></detail-modal>
		<ToPayOrMember ref="toPayOrMember" :conPO="conPO"></ToPayOrMember>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import MemberModal from "@/components/MemberModal";
import detailModal from "./detailModal/index";
import ToPayOrMember from "@/components/ToPayOrMember";
export default {
	components: {
		Pagination,
		Empty,
		MemberModal,
		detailModal,
		ToPayOrMember,
	},
	props: ["conPO"],
	data() {
		return {
			showPayBtn: false,
			total: 0,
			tableData: [],
			params: {},
			projectAllCount: 0,
			projectAllPrice: 0,
			tendereeAllCount: 0,
			bidWinnerAllCount: 0,
			typeSort: [false, false, false, false],
			initParams: {
				pageSize: 20, //页数
				pageNum: 1, // 页码
			},
			isTime: true,
			isSensitive: false,
			orderData: {},
			memberId: null,
			downloadButtonShow: null,
		};
	},
	mounted() {
		//   this.$refs.pagination.pageSize = 50;
		// this.toPay()
		let user = this.$session.getUsers();
		this.memberId = user.memberId;
		this.getDownloadButtonShow();
	},
	methods: {
		// 获取下载按钮是否显示
		getDownloadButtonShow() {
			let params = {
				childModuleCode: 10021002,
				memberId: this.memberId,
			};
			this.$api.personalCenter.getDownloadButtonShow(params).then(res => {
				console.log("res", res);
				this.downloadButtonShow = res.data.downloadButtonShow == "10011001" ? true : false;
			});
		},
		toPay() {
			// this.$refs.toPayOrMember.openDialog();
			this.showPayBtn = true;
			const query = 0;
			const params = this.conPO;
			this.$api.successful
				.getPayOrderDeatil(query, {...params, isHistoryPro: false})
				.then(res => {
					console.log("订单信息", res);
					this.orderData = res.data;
					this.$refs.toPayOrMember.orderData = res.data;
					this.$refs.toPayOrMember.openDialog();
				})
				.catch(error => {
					console.log("error", error);
					this.$message.error(error?.msg);
				});
		},
		renderHeader(h, {column, $index}) {
			if (column.order === "ascending") {
				return (
					<div>
						<span>{column.label}</span>
						<i style='margin-left: 4px;color: #1181FA;' class='iconfont icon-shengxu'></i>
					</div>
				);
			} else if (column.order === "descending") {
				return (
					<div>
						<span>{column.label}</span>
						<i style='margin-left: 4px;color: #1181FA;' class='iconfont icon-jiangxu'></i>
					</div>
				);
			} else {
				return (
					<div>
						<span>{column.label}</span>
						<i style='margin-left: 4px;color: #333;' class='iconfont icon-jiangxu'></i>
					</div>
				);
			}
		},
		// 底部合计
		getSummaries(param) {
			const {columns, data} = param;
			const sums = [];
			columns.forEach((column, index) => {
				if (index === 0) {
					sums[index] = "合计";
					return;
				}
				if (index === 2) {
					sums[index] = this.projectAllCount || 0;
					return;
				}
				if (index === 3) {
					sums[index] = this.projectAllPrice instanceof Number ? this.projectAllPrice.toFixed(4) : this.projectAllPrice;
					return;
				}
				if (index === 4) {
					sums[index] = this.tendereeAllCount || 0;
					return;
				}
				if (index === 5) {
					sums[index] = this.bidWinnerAllCount || 0;
					return;
				}
			});
			return sums;
		},
		getTableData(params) {
			// this.$refs.pagination.page = 1;
			// this.$refs.pagination.pageSize = 50;
			// params.pageNum = 1;
			// params.pageSize = 50;
			params.proPriceSort = "";
			params.proCountSort = "";
			params.treCountSort = "";
			params.bidCountSort = "";
			this.params = params;
			this.$refs.tableList.clearSort();
			this.getStatisticsByArea(params);
		},
		// 获取表格数据
		getStatisticsByArea(params) {
			const loading = this.$loading({
				lock: true,
				text: "搜索中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.$api.successful
				.getStatisticsByArea(params)
				.then(res => {
					console.log("res", res);
					let data = res.data.data;
					data.forEach((item, index) => {
						item.id = index + 100000001; // 防止和statisticsDtlVoList的id重复
						item.projectType = "汇总";
						item.projectCount = item.projectAllCount != null ? item.projectAllCount : item.projectAllCountPrivate;
						item.projectPrice = item.projectAllPrice != null ? item.projectAllPrice : item.projectAllPricePrivate;
						item.projectPrice =item.projectPrice * 1
						item.tendereeCount = item.tendereeAllCount != null ? item.tendereeAllCount : item.tendereeAllCountPrivate;
						item.bidWinnerCount = item.bidWinnerAllCount != null ? item.bidWinnerAllCount : item.bidWinnerAllCountPrivate;
						if (item.statisticsDtlVoList) {
							item.statisticsDtlVoList.forEach((key, keyIndex) => {
								key.id = +(index + 1 + "" + (keyIndex + 1));
								key.area = "";
								key.areaName = item.province + (item.city ? ";" + item.city : "") + (item.projectCounty ? ";" + item.projectCounty : "");
								key.projectCount = key.projectCount || key.projectCountPrivate;
								key.bidWinnerCount = key.bidWinnerCount || key.bidWinnerCountPrivate;
								key.projectPrice = key.projectPrice || key.projectPricePrivate;
								key.projectPrice =key.projectPrice * 1
								key.tendereeCount = key.tendereeCount || key.tendereeCountPrivate;
							});
						} else {
							item.statisticsDtlVoList = [];
						}
					});
					this.isSensitive = res.data.isSensitive || false;
					this.tableData = data;
					this.total = res.data.total;
					this.isTime = true;
					if (res.total != 0) {
						if (params.dateStart || params.dateEnd) {
							this.isTime = false;
						}
					} else {
						this.isTime = false;
					}
					this.bidWinnerAllCount = res.data.statisticsVoAll.bidWinnerAllCount != null ? res.data.statisticsVoAll.bidWinnerAllCount : res.data.statisticsVoAll.bidWinnerAllCountPrivate;
					this.projectAllCount = res.data.statisticsVoAll.projectAllCount != null ? res.data.statisticsVoAll.projectAllCount : res.data.statisticsVoAll.projectAllCountPrivate;
					this.projectAllPrice = res.data.statisticsVoAll.projectAllPrice != null ? res.data.statisticsVoAll.projectAllPrice : res.data.statisticsVoAll.projectAllPricePrivate;
					this.tendereeAllCount = res.data.statisticsVoAll.tendereeAllCount != null ? res.data.statisticsVoAll.tendereeAllCount : res.data.statisticsVoAll.tendereeAllCountPrivate;
				})

				.catch(msg => {
					if (msg.code === 10000001) {
						this.$emit("handleTest");
					}
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		// 点击下载
		toDownload() {
			let {params} = this;
			let paramsObj = JSON.parse(JSON.stringify(params));
			// delete paramsObj.pageNum;
			// delete paramsObj.pageSize;
			const loading = this.$loading({
				lock: true,
				text: "导出中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.$api.successful
				.exportStatisticsByArea(paramsObj)
				.then(res => {
					if (res.type == "application/json") {
						let that = this;
						let reader = new FileReader(); //使用 new FileReader()，将 blob 转为 json，然后进行处理
						reader.readAsText(res);
						reader.onload = function(result) {
							let resData = JSON.parse(result.target.result); // 解析对象成功，说明是json数据
							loading.close();
							if (resData.code == "10001111") {
								that.showPayBtn = true;
								that.$refs.toPayOrMember.openDialog();
							} else {
								that.$message.error(resData?.msg);
							}
						};
					} else {
						this.$utils.downloadFile(res, "统计数据-按地区.xlsx");
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		// 点击详情
		toDetail(item, type) {
			let {params, initParams} = this;
			console.log("item", item);
			if (params.projectTypeList) {
				initParams.projectType = item.projectType == "汇总" ? params.projectTypeList.toString() : item.projectType;
			} else {
				initParams.projectType = item.projectType == "汇总" ? "" : item.projectType;
			}

			initParams.area = item.area ? item.province + (item.city ? ";" + item.city : "") + (item.projectCounty ? ";" + item.projectCounty : "") : item.areaName;
			initParams.winnerPriceStart = params.winnerPriceStart;
			initParams.winnerPriceEnd = params.winnerPriceEnd;
			initParams.dateStart = params.dateStart;
			initParams.dateEnd = params.dateEnd;
			if (type == 1) {
				this.$refs.detaiModal.visible = true;
				this.$refs.detaiModal.init(1, initParams);
			} else {
				this.$refs.detaiModal.visible = true;
				this.$refs.detaiModal.init(2, initParams);
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
