<template>
	<div class="project-modal">
		<Modal :visible.sync="visible">
			<div slot="title" style="padding-left: 20px; border-left: 4px solid #fff">项目信息</div>
			<div class="detail-list">
				<div class="empty-box" v-show="total == 0">
					<empty :name="'暂无数据'"></empty>
				</div>
				<!-- <div class="download-main" v-show="total != 0&&downloadButtonShow">
					<div class="download-box" @click="toDownload">
						<span class="iconfont icon-download"></span>
						<span>下载</span>
					</div>
				</div> -->
				<div class="result-box" v-show="total != 0">
					<el-table :data="tableData" v-table-move="['table']" style="width: 100%" height="calc(100vh - 250px)">
						<el-table-column prop="index" label="序号" width="74" align="center"></el-table-column>

						<el-table-column prop="projectName" label="项目名称" width="300" show-overflow-tooltip align="center">
							<template slot-scope="scope">
								<div class="text-ellipsis">
									{{ scope.row.projectName }}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="province" label="省份" width="110" align="center" show-overflow-tooltip></el-table-column>
						<el-table-column prop="city" label="地市" width="110" align="center" show-overflow-tooltip></el-table-column>
						<el-table-column prop="projectCounty" label="区/县" width="110" align="center" show-overflow-tooltip></el-table-column>
						<el-table-column prop="projectType" label="项目类型" width="110" align="center" show-overflow-tooltip></el-table-column>
						<!-- :width="flexColumnWidth('tenderee', tableData)" -->
						<!-- :width="flexColumnWidth('bidWinner', tableData)" -->
						<!-- :width="flexColumnWidth('bidConsortium', tableData)" -->
						<el-table-column prop="tenderee" label="招标人（牵头人）" width="200" align="center" show-overflow-tooltip>
							<template slot-scope="scope">
								<div class="text-ellipsis">
									{{ scope.row.tenderee }}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="tendereeConsortium" label="招标人（联合体）" width="200" align="center" show-overflow-tooltip>
							<template slot-scope="scope">
								<div class="text-ellipsis" v-for="(item, index) in scope.row.tendereeConsortium" :key="index">
									<el-tooltip :content="item" placement="top">
										<span>{{ item }}</span>
									</el-tooltip>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="bidWinner" label="中标人（牵头人）" width="200" align="center" show-overflow-tooltip>
							<template slot-scope="scope">
								<div class="text-ellipsis">
									{{ scope.row.bidWinner }}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="bidConsortium" label="中标人（联合体）" width="200" align="center" show-overflow-tooltip>
							<template slot-scope="scope">
								<div class="text-ellipsis" v-for="(item, index) in scope.row.bidConsortium" :key="index">
									<el-tooltip :content="item" placement="top">
										<span>{{ item }}</span>
									</el-tooltip>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="winnerPrice" label="中标价（万元）" width="200" align="center" show-overflow-tooltip></el-table-column>
						<el-table-column prop="publishedDate" label="中标时间" width="110" align="center" show-overflow-tooltip></el-table-column>
						<el-table-column prop="projectLink" label="中标公告原文链接" width="180" align="center" show-overflow-tooltip v-if="linkShow">
							<template slot-scope="scope">
								<span class="project-link" @click="toLink(scope.row.projectLink)">{{ scope.row.projectLink }}</span>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="pagination-box">
					<pagination ref="pagination" :total="total" @change="paginChange" v-show="total != 0"></pagination>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script>
import Modal from "@/components/Modal";
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
export default {
	components: {
		Modal,
		Pagination,
		Empty,
	},
	data() {
		return {
			memberId: "",
			visible: false,
			tableData: [],
			total: 0,
			conPO: {},
			linkShow: false,
			downloadButtonShow:null
		};
	},
	mounted() {
		let user = this.$session.getUsers();
		this.memberId = user.memberId;
		this.getDownloadButtonShow();
	},
	methods: {
			// 获取下载按钮是否显示
		getDownloadButtonShow() {
			let params = {
				childModuleCode: 10021003,
				memberId: this.memberId,
			};
			this.$api.personalCenter.getDownloadButtonShow(params).then(res => {
				console.log("res", res);
				this.downloadButtonShow = res.data.downloadButtonShow == "10011001" ? true : false;
			});
		},
		init(params) {
			this.visible = true;
			this.$nextTick(() => {
				this.$refs.pagination.pageSize = 20;
				this.$refs.pagination.page = 1;
			});
			params.pageNum = 1;
			this.conPO = params;
			this.tableData = [];
			this.getInfo();
		},
		// 自适应表格列宽
		flexColumnWidth(str, tableData, flag = "max") {
			// str为该列的字段名(传字符串);tableData为该表格的数据源(传变量);
			// flag为可选值，可不传该参数,传参时可选'max'或'equal',默认为'max'
			// flag为'max'则设置列宽适配该列中最长的内容,flag为'equal'则设置列宽适配该列中第一行内容的长度。
			str = str + "";
			let columnContent = "";
			if (!tableData || !tableData.length || tableData.length === 0 || tableData === undefined) {
				return;
			}
			if (!str || !str.length || str.length === 0 || str === undefined) {
				return;
			}
			if (flag === "equal") {
				// 获取该列中第一个不为空的数据(内容)
				for (let i = 0; i < tableData.length; i++) {
					if (tableData[i][str].length > 0) {
						// console.log('该列数据[0]:', tableData[0][str])
						columnContent = tableData[i][str];
						break;
					}
				}
			} else {
				// 获取该列中最长的数据(内容)
				let index = 0;
				for (let i = 0; i < tableData.length; i++) {
					if (tableData[i][str] === null) {
						return;
					}
					const now_temp = tableData[i][str] + "";
					const max_temp = tableData[index][str] + "";
					if (now_temp.length > max_temp.length) {
						index = i;
					}
				}
				columnContent = tableData[index][str] + "";
				let columnContentArr = columnContent.split(",");
				// 如果字符串转成数组大于1，去寻找数组中最长的数据

				if (columnContentArr.length > 1) {
					let newIndex = 0;
					for (let j = 0; j < columnContentArr.length; j++) {
						if (columnContentArr[j] === null) {
							return;
						}
						const now_temp = columnContentArr[j] + "";
						const max_temp = columnContentArr[newIndex] + "";
						if (now_temp.length > max_temp.length) {
							newIndex = j;
						}
					}
					columnContent = columnContentArr[newIndex];
				}
			}
			// console.log('该列数据[i]:', columnContent)
			// 以下分配的单位长度可根据实际需求进行调整
			let flexWidth = 0;
			for (const char of columnContent) {
				if ((char >= "A" && char <= "Z") || (char >= "a" && char <= "z")) {
					// 如果是英文字符，为字符分配10个单位宽度
					flexWidth += 10;
				} else if (char >= "\u4e00" && char <= "\u9fa5") {
					// 如果是中文字符，为字符分配18个单位宽度
					flexWidth += 18;
				} else {
					// 其他种类字符，为字符分配10个单位宽度
					flexWidth += 10;
				}
			}
			if (flexWidth < 60) {
				// 设置最小宽度
				flexWidth = 60;
			}
			return flexWidth;
		},
		// 获取列表数据
		getInfo() {
			let {conPO} = this;
			let params = JSON.parse(JSON.stringify(conPO));
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.$api.successful
				.getProjectInfoDetail(params)
				.then(res => {
					let data = res.rows;
					data.forEach((item, index) => {
						item.index = (conPO.pageNum - 1) * conPO.pageSize + index + 1;
						item.publishedDate = item.publishedDate.slice(0, 10);
						//中标人联合体
						if (item.bidConsortium) {
							if (item.bidConsortium.indexOf(",") == -1) {
								let arr1 = [];
								arr1.push(item.bidConsortium);
								item.bidConsortium = arr1;
								this.$forceUpdate();
							} else {
								let arr2 = item.bidConsortium.split(",");
								item.bidConsortium = arr2;
								this.$forceUpdate();
							}
							this.$forceUpdate();
						} else {
							item.bidConsortium = ["-"];
						}
						//招标人联合体
						if (item.tendereeConsortium) {
							if (item.tendereeConsortium.indexOf(",") == -1) {
								let arr1 = [];
								arr1.push(item.tendereeConsortium);
								item.tendereeConsortium = arr1;
								this.$forceUpdate();
							} else {
								let arr2 = item.tendereeConsortium.split(",");
								item.tendereeConsortium = arr2;
								this.$forceUpdate();
							}
							this.$forceUpdate();
						} else {
							item.tendereeConsortium = ["-"];
						}
						this.$forceUpdate();
					});
					this.$forceUpdate();
					this.tableData = data;
					this.total = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		paginChange(page, pageSize) {
			console.log(page, pageSize);
			this.conPO.pageNum = page;
			this.conPO.pageSize = pageSize;
			this.getInfo();
		},
		toLink(url) {
			this.$confirm("您即将跳转进入第三方网站，慧决策平台不对该网站内容及真实性负责，请注意您的账号和财产安全", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
				window.open(url, "_blank");
			});
		},
		toDownload() {
			let {params} = this;
			let paramsObj = JSON.parse(JSON.stringify(params));
			delete paramsObj.pageNum;
			delete paramsObj.pageSize;
			if (this.type == 1) {
				const loading = this.$loading({
					lock: true,
					text: "导出中...",
					spinner: "el-icon-loading",
					background: "rgba(255, 255, 255, 0.3)",
					fullscreen: false,
				});
				this.$api.successful
					.exportBidDetailForTender(paramsObj)
					.then(res => {
						this.$utils.downloadFile(res, "招标人TOP100.xlsx");
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					})
					.finally(() => {
						loading.close();
					});
			} else {
				const loading = this.$loading({
					lock: true,
					text: "导出中...",
					spinner: "el-icon-loading",
					background: "rgba(255, 255, 255, 0.3)",
					fullscreen: false,
				});
				this.$api.successful
					.exportBidDetailForBidWinner(paramsObj)
					.then(res => {
						this.$utils.downloadFile(res, "中标人TOP100.xlsx");
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					})
					.finally(() => {
						loading.close();
					});
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
